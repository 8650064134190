






























































































































































































































































































































































































import { Vue, Watch, Component } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import { IInstructor } from "@/interfaces/IInstructor";
import UserService from "@/services/UserService";
import _ from "lodash";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Monthpicker from "@/components/Monthpicker.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import { IStudentFilter } from "@/interfaces/IStudentFilter";
import { IBasicNamed } from "@/interfaces/IBasicNamed";
import moment from "moment";
import { formatInstructorName } from "@/utils/NameUtil";
import { ITheoryCourse } from "@/interfaces/ITheoryCourse";
import DefaultInstructorSorting from "@/constants/DefaultInstructorSorting";
import { LIVE_PAY } from "@/constants/PaymentWorkflow";

const BranchModule = namespace("branch");
const InstructorModule = namespace("instructor");
const LicenseClassModule = namespace("license-class");
const TheoryCourseModule = namespace("theory-course");
const PriceListModule = namespace("price-list");
const PaymentWorkflowModule = namespace("payment-workflow");
const StudentsFilterModule = namespace("students-filter");
const CostBearerModule = namespace("cost-bearer");
const GrantingTypeModule = namespace("granting-type");
const TestOrganizationModule = namespace('testing-organizations');
const grantingTypeResource = "granting-types";

@Component({
  components: {
    Datepicker,
    AbortButton,
    SaveButton,
    Validation,
    Monthpicker,
    FscInputDecimal,
  },
})
export default class StudentFilter extends Vue {
  public name = "StudentFilter";

  @BranchModule.Action("findAll")
  public findAllBranchesAction: any;

  @BranchModule.Getter("getDataList")
  public allBranches: any;

  @CostBearerModule.Action("findAll")
  public findAllCostBearers: any;

  @CostBearerModule.Getter("getDataList")
  public allCostBearers: any;

  @InstructorModule.Action("filter")
  public findAllInstructorsFilter: any;

  @InstructorModule.Getter("getDataList")
  public allInstructors: any;

  @LicenseClassModule.Action("findAll")
  public findAllLicenseClassesAction: any;

  @LicenseClassModule.Getter("getDataList")
  public allLicenseClasses: any;

  @TheoryCourseModule.Action("findStudentFilterTheoryCourses")
  public findStudentFilterTheoryCourses: any;

  @TheoryCourseModule.Getter("getFilterStudentTheoryCourses")
  public allTheoryCourses: any;

  @PriceListModule.Action("findAll")
  public findAllPriceLists: any;

  @PriceListModule.Getter("getDataList")
  public allPriceLists: any;

  @PaymentWorkflowModule.Action("findAll")
  public findAllPaymentWorkflows: any;

  @PaymentWorkflowModule.Getter("getDataList")
  public allPaymentWorkflows: any;

  @StudentsFilterModule.Action("create")
  public createStudentsFilter: any;

  @StudentsFilterModule.Getter("getId")
  public createdFilterId: any;

  @StudentsFilterModule.Getter("getSuccess")
  public studentsFilterSuccess: any;

  @StudentsFilterModule.Action("findFilterByInstructorId")
  public findAllStudentsFilters: any;

  @StudentsFilterModule.Getter("getDataList")
  public allStudentsFilters: any;

  @StudentsFilterModule.Action("update")
  public studentsFilterUpdateById: any;

  @StudentsFilterModule.Getter("getIsLoading")
  public studentsFilterLoading: any;

  @StudentsFilterModule.Getter("getCurrentFilter")
  public getCurrentFilter?: IStudentFilter;

  // Granting type
  @GrantingTypeModule.Action("findAll")
  public findAllGrantingType!: (options: ICrudOptions) => Promise<void>;

  @GrantingTypeModule.Getter("getDataList")
  public grantingTypes!: Array<IBasicNamedDTO>;

  @StudentsFilterModule.Mutation("SET_CURRENT_FILTER")
  public setCurrentFilter!: any;

  @TestOrganizationModule.Action("findAll")
  public findAllTestOrganizationsAction: any;

  @TestOrganizationModule.Getter("getDataList")
  public allTestOrganizations: any;

  public selectedBranches: IBasicNamed[] = [];

  public selectedInstructors: IBasicNamed[] = [];

  public selectedLicenseClasses: Array<{ name: string }> = [];

  public selectedTheoryCourses: IBasicNamed[] = [];

  public selectedPriceLists: IBasicNamed[] = [];

  public selectedCostBearerTypes: IBasicNamed[] = [];

  public selectedBf17: any = null;

  public selectedGrantingType: any = null;

  public selectedDriveBuzz: any = null;

  public selectedTheoryExamStatus: any = null;

  public selectedPracticalExamStatus: any = null;

  public selectedSpecialDrivingLesson: any = null;

  public selectedTestOrganisation: any = null;

  public selectedPaymentWorkflow: any = null;

  public selectedLivePayMandateStatus: any = null;

  public paymentTypeLivePay = LIVE_PAY;

  public selectedFilter: any = null;

  public fillingDate: any = null;

  public fillingDateFrom: any = null;

  public fillingDateTo: any = null;

  public fillingDateYearMonth: any = null;

  public inactiveDays: any = null;

  public archivable: any = null;

  public lastNameStartingLetter = "";

  public filterName = "";

  public filterUpdate = false;

  public filterActive = false;

  public hasEducationContract: any = null;

  public inactivePeriodFrom: any = null;

  public inactivePeriodTo: any = null;

  private selectedTheoryLessons: any = null;

  public showArchived = false;
  public includeArchivedEducations = false;

  public previewBalance = false;

  public balanceMoreThan: any = null;

  private selectedAppInfo: any = null;

  public educationContractOptions = [
    { name: "Aubi-Vertrag liegt vor", value: true },
    { name: "Aubi-Vertrag fehlt", value: false },
  ];

  public driveBuzzOptions = [
    {
      id: 0,
      name: "100% Lernstatus",
    },
    {
      id: 1,
      name: "4 Vorprüfungen nacheinander bestanden",
    },
    {
      id: 2,
      name: "100% + 4 Vorprüfungen nacheinander bestanden",
    },
  ];

  public theoryAndPracticeOptions = [
    { passed: true, name: "Bestanden" },
    { passed: false, name: "Nicht Bestanden" },
  ];

  public specialDrivingLessonsOptions = [
    { id: 0, name: "Komplett" },
    { id: 1, name: "Teilweise" },
    { id: 2, name: "Keine" },
  ];

  public archivableOptions = [
    { name: "Ja", archivable: true },
    { name: "Nein", archivable: false },
  ];

  public alphabetOptions = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  public user = this.currentUser || null;

  public mounted(): void {
    this.findAllBranchesAction({
      resource: "branches",
      params: { archived: false },
    });
    this.findAllInstructorsFilter({
      resource: "instructor",
      filter: {
        archived: false,
        ...DefaultInstructorSorting,
      },
    });
    this.findAllLicenseClassesAction({ resource: "license-classes" });
    this.findStudentFilterTheoryCourses();
    this.findAllPaymentWorkflows({ resource: "payment-workflows" });
    this.findAllPriceLists({ resource: "price-lists" });
    this.findAllCostBearers({ resource: "cost-bearers/" });
    this.findAllStudentsFilters(this.user.id);
    this.findAllGrantingType({
      resource: grantingTypeResource,
      descriptionField: "",
    });
    this.findAllTestOrganizationsAction({ resource: "testing-organizations/" });

    this.initStoredFilter();
  }

  public onAbortFilter(): void {
    this.selectedBranches = [];
    this.selectedInstructors = [];
    this.selectedLicenseClasses = [];
    this.selectedTheoryCourses = [];
    this.selectedPriceLists = [];
    this.selectedCostBearerTypes = [];
    this.selectedBf17 = false;
    this.showArchived = false;
    this.includeArchivedEducations = false;
    this.selectedGrantingType = null;
    this.selectedDriveBuzz = null;
    this.selectedTheoryExamStatus = null;
    this.selectedPracticalExamStatus = null;
    this.selectedSpecialDrivingLesson = null;
    this.selectedTestOrganisation = null;
    this.selectedTheoryLessons = null;
    this.selectedPaymentWorkflow = null;
    this.selectedLivePayMandateStatus = null;
    this.selectedAppInfo = null;
    this.fillingDate = null;
    this.fillingDateTo = null;
    this.fillingDateFrom = null;
    this.inactivePeriodFrom = null;
    this.inactivePeriodTo = null;
    this.archivable = null;
    this.fillingDateYearMonth = null;
    this.lastNameStartingLetter = "";
    this.filterName = "";
    this.hasEducationContract = null;
    this.previewBalance = false;
    this.balanceMoreThan = null;
    this.selectedFilter = null;
    this.filterUpdate = false;

    const filter = {} as IStudentFilter;

    this.$emit("abort-filter", {
      filter: filter,
    });
  }

  public get currentUser(): any {
    return UserService.getUser();
  }

  public onFilter(): void {
    const filter: any = {
      studentFilter: this.selectedFilter || null,
      name: this.filterName,
      fillingDate: this.fillingDate,
      fillingDateFrom: this.fillingDateFrom,
      fillingDateTo: this.fillingDateTo,
      inactivePeriodFrom: this.inactivePeriodFrom,
      inactivePeriodTo: this.inactivePeriodTo,
      fillingDateYearMonth: this.fillingDateYearMonth,
      showArchived: this.showArchived,
      includeArchivedEducations: this.includeArchivedEducations,
      bf17: this.selectedBf17,
      driveBuzz: this.selectedDriveBuzz,
      theoryLessons: this.selectedTheoryLessons,
      appInfo: this.selectedAppInfo,
      archivable: this.archivable,
      instructors: this.selectedInstructors,
      branches: this.selectedBranches,
      theoryCourses: this.selectedTheoryCourses,
      licenseClasses: this.selectedLicenseClasses,
      priceLists: this.selectedPriceLists,
      costBearers: this.selectedCostBearerTypes,
      lastNameStartingLetter: this.lastNameStartingLetter,
      grantingType: this.selectedGrantingType,
      theoryExamPassed: this.selectedTheoryExamStatus === null ? null : this.selectedTheoryExamStatus?.passed,
      practicalExamPassed: this.selectedPracticalExamStatus === null ? null : this.selectedPracticalExamStatus?.passed,
      paymentWorkflow: this.selectedPaymentWorkflow,
      livePayMandateStatus : this.selectedLivePayMandateStatus,
      publicFilter: true,
      hasSignedContract: this.hasEducationContract,
      previewBalance: this.previewBalance,
      balanceMoreThan: this.balanceMoreThan,
      specialDrivingLesson: this.selectedSpecialDrivingLesson,
      testOrganisation: this.selectedTestOrganisation,
      savedAt: moment.now().toString(),
    };
    this.$emit("on-filter", {
      filter: filter,
    });
  }

  public async onSubmitFilter(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    //FILTER DATA
    const filterDataObj = {
      fillingDate: this.fillingDate,
      fillingDateFrom: this.fillingDateFrom,
      fillingDateTo: this.fillingDateTo,
      inactivePeriodFrom: this.inactivePeriodFrom,
      inactivePeriodTo: this.inactivePeriodTo,
      fillingDateYearMonth: this.fillingDateYearMonth,
      showArchived: this.showArchived,
      includeArchivedEducations: this.includeArchivedEducations,
      bf17: this.selectedBf17 ? this.selectedBf17 : null,
      driveBuzz: this.selectedDriveBuzz ? this.selectedDriveBuzz.id : null,
      theoryLesson: this.selectedTheoryLessons ? this.selectedTheoryLessons.id : null,
      appInfo: this.selectedAppInfo ? this.selectedAppInfo.id : null,
      archivable: this.archivable ? this.archivable.archivable : null,
      instructors: this.selectedInstructors,
      branches: this.selectedBranches,
      theoryCourses: this.selectedTheoryCourses,
      licenseClasses: this.selectedLicenseClasses,
      priceLists: this.selectedPriceLists,
      costBearers: this.selectedCostBearerTypes,
      lastNameStartingLetter: this.lastNameStartingLetter,
      grantingType: this.selectedGrantingType,
      theoryExamPassed: !this.selectedTheoryExamStatus ? null : this.selectedTheoryExamStatus?.passed,
      practicalExamPassed: !this.selectedPracticalExamStatus ? null : this.selectedPracticalExamStatus?.passed,
      paymentWorkflow: this.selectedPaymentWorkflow,
      livePayMandateStatus: this.selectedLivePayMandateStatus,
      hasSignedContract: this.hasEducationContract ? this.hasEducationContract.value : null,
      previewBalance: this.previewBalance,
      balanceMoreThan: this.balanceMoreThan ? this.balanceMoreThan : null,
      specialDrivingLesson: this.selectedSpecialDrivingLesson ?? null,
      testOrganisation: this.selectedTestOrganisation ?? null
    };
    //FILTER DATA STRINGIFIED
    const filterDataStringified = JSON.stringify(filterDataObj);
    //FILTER OBJECT
    const filter = {
      id: this.selectedFilter ? this.selectedFilter.id : null,
      name: this.filterName,
      user: {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        initials: this.user.initials,
      },
      publicFilter: true,
      filterData: filterDataStringified,
    };
    if (this.selectedFilter === null || this.selectedFilter.id === null) {
      this.createStudentsFilter({
        data: filter,
        resource: "students-filter",
      });
    } else {
      await this.studentsFilterUpdateById({
        id: this.selectedFilter.id,
        data: filter,
        resource: "students-filter",
      });

      if (this.studentsFilterSuccess) {
        this.setCurrentFilter({
          ...this.getCurrentFilter,
          studentFilter: filter,
        });
      }
    }
  }

  public get bf17Enabled(): any {
    let disabled = true;
    if (this.selectedLicenseClasses) {
      this.selectedLicenseClasses.map((licenseClass: any) => {
        if (_.includes(licenseClass, "B") || _.includes(licenseClass, "B96") || _.includes(licenseClass, "B197")) {
          return (disabled = false);
        }
      });
    }
    return disabled;
  }

  public get branchOptions(): Array<any> {
    return this.allBranches.map((branch: any) => {
      return {
        name: `${branch.abbreviation}`,
        id: branch.id,
      };
    });
  }

  public get testOrganisationOptions(): Array<any> {
    return this.allTestOrganizations.map((to: any) => {
      return {
        name: `${to.name}`,
        id: to.id,
      };
    });
  }

  public get costBearerTypeIdOptions(): Array<any> {
    return this.allCostBearers.map((costBearer: any) => {
      return {
        name: `${costBearer.name}`,
        id: costBearer.id,
      };
    });
  }

  public get customFiltersOptions(): Array<any> {
    return this.allStudentsFilters;
  }

  public get grantingTypesOptions(): Array<IBasicNamedDTO> {
    return this.grantingTypes;
  }

  public get instructorOptions(): Array<any> {
    return this.allInstructors.reduce(
      (previousValue: any, currentValue: any) => {
        previousValue.push({
          name: formatInstructorName(currentValue.firstName, currentValue.lastName),
          id: currentValue.id,
        });
        return previousValue;
      },
      [
        {
          name: "-kein Fahrlehrer-",
          id: -1,
        },
      ]
    );
  }

  public instructorFormatting(instructors: any): any {

    // this was causing problem when loading a filter which has no instructor!
    if (instructors === undefined || instructors === null) {
      return null;
    }

    return instructors.map((instructor: any) => {
      if (instructor.firstName) {
        return {
          name: formatInstructorName(instructor.firstName, instructor.lastName),
          id: instructor.id
        };
      } else {
        return {
          name: `${instructor.name}`,
          id: instructor.id
        };
      }
    });
  }

  public driveBuzzFormatting(driveBuzzId: any) {
    let driveBuzz = null;
    switch (driveBuzzId) {
      case 0:
        driveBuzz = { id: 0, name: "100% Lernstatus" };
        break;
      case 1:
        driveBuzz = {
          id: 1,
          name: "4 Vorprüfungen nacheinander bestanden",
        };
        break;
      case 2:
        driveBuzz = {
          id: 2,
          name: "100% + 4 Vorprüfungen nacheinander bestanden",
        };
        break;
    }
    return driveBuzz;
  }

  public theoryLessonsFormatting(theoryLessonId: any) {
    let theoryLesson = null;
    switch (theoryLessonId) {
      case 0:
        theoryLesson = {
          id: 0,
          name: "Komplett",
        };
        break;
      case 1:
        theoryLesson = {
          id: 1,
          name: "Teilweise",
        };
        break;
      case 2:
        theoryLesson = {
          id: 2,
          name: "Keine",
        };
        break;
    }
    return theoryLesson;
  }

  public get licenseClassOptions(): Array<any> {
    return this.allLicenseClasses.map((licenseClass: any) => {
      return {
        name: `${licenseClass}`,
      };
    });
  }

  public get paymentWorkflowOptions(): Array<any> {
    return this.allPaymentWorkflows;
  }

  public get priceListOption(): Array<any> {
    return this.allPriceLists.map((priceList: any) => {
      return {
        name: `${priceList.name}`,
        id: priceList.id,
      };
    });
  }

  public get theoryCourseOptions(): Array<ITheoryCourse> {
    return this.allTheoryCourses.map((theoryCourse: any) => {
      return {
        name: `${theoryCourse.name} [${moment(theoryCourse.startDate).format("DD.MM.YYYY")} - ${moment(theoryCourse.endDate).format("DD.MM.YYYY")}]`,
        id: theoryCourse.theoryCourseId,
      };
    });
  }

  @Watch("selectedFilter")
  public onSelectedFilterChange(filtera: any) {
    if (filtera && filtera.filterData) {
      this.filterName = filtera.name;
      const filter = JSON.parse(filtera.filterData);
      this.selectedBranches = filter.branches;
      this.selectedInstructors = this.instructorFormatting(filter.instructors);
      this.selectedLicenseClasses = filter.licenseClasses;
      this.selectedTheoryCourses = filter.theoryCourses;
      this.showArchived = filter.showArchived;
      this.includeArchivedEducations = filter.showArchived == true ? true : filter.includeArchivedEducations;
      this.selectedPriceLists = filter.priceLists;
      this.selectedCostBearerTypes = filter.costBearers;
      this.selectedBf17 = filter.bf17 ? filter.bf17 : null;
      this.selectedGrantingType = filter.grantingType;
      this.selectedDriveBuzz = this.driveBuzzFormatting(filter.driveBuzz);
      this.selectedTheoryLessons = this.theoryLessonsFormatting(filter.theoryLesson);
      this.selectedAppInfo = this.appInfoFormatting(filter.appInfo);
      if (filter.theoryExamPassed === null) {
        this.selectedTheoryExamStatus = null;
      } else if (filter.theoryExamPassed) {
        this.selectedTheoryExamStatus = { passed: true, name: "Bestanden" };
      } else if (!filter.theoryExamPassed) {
        this.selectedTheoryExamStatus = {
          passed: false,
          name: "Nicht Bestanden",
        };
      }
      if (filter.practicalExamPassed === null) {
        this.selectedPracticalExamStatus = null;
      } else if (filter.practicalExamPassed) {
        this.selectedPracticalExamStatus = { passed: true, name: "Bestanden" };
      } else if (!filter.practicalExamPassed) {
        this.selectedPracticalExamStatus = {
          passed: false,
          name: "Nicht Bestanden",
        };
      }

      this.selectedSpecialDrivingLesson = filter.specialDrivingLesson;
      this.selectedTestOrganisation = filter.testOrganisation;
      this.selectedPaymentWorkflow = filter.paymentWorkflow;
      this.selectedLivePayMandateStatus = filter.livePayMandateStatus;
      this.fillingDate = filter.fillingDate;
      this.fillingDateFrom = filter.fillingDateFrom;
      this.fillingDateTo = filter.fillingDateTo;
      this.inactivePeriodTo = filter.inactivePeriodTo;
      this.inactivePeriodFrom = filter.inactivePeriodFrom;
      this.previewBalance = filter.previewBalance;
      this.balanceMoreThan = filter.balanceMoreThan ? filter.balanceMoreThan : null;
      if (filter.archivable === null) {
        this.archivable = null;
      } else if (filter.archivable) {
        this.archivable = { name: "Ja", archivable: true };
      } else if (!filter.archivable) {
        this.archivable = { name: "Nein", archivable: false };
      }
      this.fillingDateYearMonth = filter.fillingDateYearMonth;
      this.lastNameStartingLetter = filter.lastNameStartingLetter;
      if (filter.hasSignedContract === null) {
        this.hasEducationContract = null;
      } else {
        this.hasEducationContract = this.educationContractOptions.find((contract: any) => contract.value === filter.hasSignedContract);
      }
    }
  }

  @Watch("studentsFilterSuccess")
  public async onStudentsFilterSuccess(success: any): Promise<void> {
    if (success && !this.filterUpdate) {
      await this.findAllStudentsFilters(this.user.id);
      const mapfilterId = this.createdFilterId;
      if (this.allStudentsFilters && mapfilterId) {
        this.allStudentsFilters.map((mapfilter: any) => {
          if (mapfilter.id === mapfilterId) {
            return (this.selectedFilter = mapfilter);
          }
        });
      }
    } else if (success && this.filterUpdate) {
      const updateFilterId = this.selectedFilter.id;
      await this.findAllStudentsFilters(this.user.id);
      if (this.allStudentsFilters && updateFilterId) {
        this.allStudentsFilters.map((mapfilter: any) => {
          if (mapfilter.id === updateFilterId) {
            return (this.selectedFilter = mapfilter);
          }
        });
      }
    }
  }

  @Watch("filterName")
  public onStudentsFilterNameChange(name: any): void {
    if (name && this.selectedFilter) {
      this.filterUpdate = true;
    }
  }
  @Watch("showArchived")
  public onShowArchivedChange(newVal: any): void {
    this.includeArchivedEducations = newVal === true ? true : false;
    this.filterUpdate = true;
  }

  @Watch("selectedPaymentWorkflow")
  public onPaymentWorkflowChange(newVal: any): void {

    if (newVal != null && newVal.id === this.paymentTypeLivePay) {
      // set the first LivePayMandateStatusFilter option as default (selected)
      if (this.selectedLivePayMandateStatus == null) { // reset only when is null, not e.g when modal is being opened and has a value
        this.selectedLivePayMandateStatus = this.livePayMandateStatusOptions[0];
      }
    } else {
      this.selectedLivePayMandateStatus = null;
    }
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      filterName: { required },
    };
  }

  public selectedMonth(data: any): void {
    this.fillingDateYearMonth = data;
  }

  public initStoredFilter(): void {
    const savedFilterFilter: any = this.getCurrentFilter;

    if (savedFilterFilter) {
      if (savedFilterFilter.studentFilter?.id) {
        this.filterName = savedFilterFilter.name;
        this.selectedFilter = savedFilterFilter.studentFilter;

        return;
      }

      this.selectedBranches = savedFilterFilter.branches;
      this.selectedInstructors = savedFilterFilter.instructors;
      this.selectedLicenseClasses = savedFilterFilter.licenseClasses;
      this.selectedTheoryCourses = savedFilterFilter.theoryCourses;
      this.selectedPriceLists = savedFilterFilter.priceLists;
      this.selectedCostBearerTypes = savedFilterFilter.costBearers;
      this.selectedBf17 = savedFilterFilter.bf17 ?? null;
      this.showArchived = savedFilterFilter.showArchived ?? false;
      this.includeArchivedEducations = savedFilterFilter.includeArchivedEducations ?? false;
      this.previewBalance = savedFilterFilter.previewBalance ?? false;
      this.balanceMoreThan = savedFilterFilter.balanceMoreThan ?? null;
      this.selectedGrantingType = savedFilterFilter.grantingType;
      this.selectedDriveBuzz = savedFilterFilter.driveBuzz;
      this.selectedTheoryLessons = savedFilterFilter.theoryLessons;
      this.selectedAppInfo = savedFilterFilter.appInfo;
      if (savedFilterFilter.theoryExamPassed === null) {
        this.selectedTheoryExamStatus = null;
      } else if (savedFilterFilter.theoryExamPassed === true) {
        this.selectedTheoryExamStatus = { passed: true, name: "Bestanden" };
      } else if (savedFilterFilter.theoryExamPassed === false) {
        this.selectedTheoryExamStatus = {
          passed: false,
          name: "Nicht Bestanden",
        };
      }
      if (savedFilterFilter.practicalExamPassed === null) {
        this.selectedPracticalExamStatus = null;
      } else if (savedFilterFilter.practicalExamPassed === true) {
        this.selectedPracticalExamStatus = { passed: true, name: "Bestanden" };
      } else if (savedFilterFilter.practicalExamPassed === false) {
        this.selectedPracticalExamStatus = {
          passed: false,
          name: "Nicht Bestanden",
        };
      }
      this.selectedSpecialDrivingLesson = savedFilterFilter.specialDrivingLesson;
      this.selectedTestOrganisation = savedFilterFilter.testOrganisation;
      this.selectedPaymentWorkflow = savedFilterFilter.paymentWorkflow;
      this.selectedLivePayMandateStatus = savedFilterFilter.livePayMandateStatus;
      this.fillingDate = savedFilterFilter.fillingDate;
      this.fillingDateFrom = savedFilterFilter.fillingDateFrom;
      this.fillingDateTo = savedFilterFilter.fillingDateTo;
      this.inactivePeriodFrom = savedFilterFilter.inactivePeriodFrom;
      this.inactivePeriodTo = savedFilterFilter.inactivePeriodTo;
      if (savedFilterFilter.archivable === null) {
        this.archivable = null;
      } else if (savedFilterFilter.archivable?.archivable) {
        this.archivable = { name: "Ja", archivable: true };
      } else if (!savedFilterFilter.archivable?.archivable) {
        this.archivable = { name: "Nein", archivable: false };
      }
      this.fillingDateYearMonth = savedFilterFilter.fillingDateYearMonth;
      this.lastNameStartingLetter = savedFilterFilter.lastNameStartingLetter;
      if (Object.keys(savedFilterFilter).includes("hasSignedContract")) {
        this.hasEducationContract = this.educationContractOptions.find((item) => item.value === savedFilterFilter.hasSignedContract?.value);
      } else {
        this.hasEducationContract = null;
      }
    }
  }

  public get theoryLessonsOptions() {
    return [
      {
        id: 0,
        name: "Komplett",
      },
      {
        id: 1,
        name: "Teilweise",
      },
      {
        id: 2,
        name: "Keine",
      },
    ];
  }

  public get livePayMandateStatusOptions() {
    return [
      {
        id: 0,
        name: "Alle",
      },
      {
        id: 1,
        name: "Aktiv",
      },
      {
        id: 2,
        name: "Inaktiv",
      },
    ];
  }

  public get appInfoOptions() {
    return [
      {
        id: 0,
        name: "Nicht abgegeben",
      },
      {
        id: 1,
        name: "Bereits abgegeben",
      },
      {
        id: 2,
        name: "Prüfauftrag vorhanden",
      },
    ];
  }

  public appInfoFormatting(appInfoId: any) {
    let appInfo = null;
    switch (appInfoId) {
      case 0:
        appInfo = {
          id: 0,
          name: "Nicht abgegeben",
        };
        break;
      case 1:
        appInfo = {
          id: 1,
          name: "Bereits abgegeben",
        };
        break;
      case 2:
        appInfo = {
          id: 2,
          name: "Prüfauftrag vorhanden",
        };
        break;
    }
    return appInfo;
  }

  private get instructorFieldIsMultiple() {
    return this.selectedInstructors instanceof Array ? this.selectedInstructors.findIndex((ins: any) => ins.id === -1) == -1 : true;
  }

  private onSelectInstructor(value: any) {
    let val = -1;
    if (value instanceof Array) {
      val = value?.findIndex((ins: any) => ins.id === -1);
    }

    if (val == -1) {
      this.selectedInstructors = value instanceof Array ? value : [value];
    } else {
      this.selectedInstructors = [value[val]];
    }
  }

  private onDeselectInstructor(value: any) {
    const index = this.selectedInstructors?.findIndex((ins: any) => ins.id === value.id);
    this.selectedInstructors.splice(index, 1);
  }
}
